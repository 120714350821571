.contract-priority-select-wrapper {
  position: relative;
  .contract-priority-indicator {
    position: absolute;
    left: 0.42em;
    top: 0.28em;
    &::before,
    &::after {
      left: 0;
      top: 0;
      position: absolute;
      display: block;
      width: 1.1em;
      height: 1.1em;
      background: transparent;
      z-index: 0;
      opacity: 0.8;
      content: '';
    }
    &::after {
      left: 1.1em;
    }
  }
  .select.contract-priority-select {
    display: inline-block;
    .icon {
      height: 1em;
      margin-top: -0.3em;
    }
    padding: 0 0.6em;
    height: 1.6em;
    width: 4.2em;
    background-position: 2.3em;
    font-weight: bold;
    background-color: transparent;
    border-color: transparent;
    position: relative;
  }
  .contract-priority-indicator {
    &[data-contract='A']::before,
    &[data-priority='Prio1']::after {
      background: lime;
    }

    &[data-contract='B']::before,
    &[data-priority='Prio2']::after {
      background: yellow;
    }

    &[data-priority='Prio3']::after {
      background: darkorange;
    }

    &[data-contract='C']::before {
      background: lightgray;
    }

    &[data-contract='G']::before,
    &[data-priority='Prio4']::after {
      background: lightgray;
    }

    &[data-contract='X']::before {
      background: lightgray;
    }
  }
}
.contract-type,
.priority-type {
  position: relative;
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  box-sizing: border-box;
  line-height: 1.1em;
  padding-left: 0.23em;
  font-weight: bold;
  &:not(.no-rating-text) {
    color: black !important;
  }
  font-size: 0.8em;

  &[data-color='A']{
    background: #9ae09a;
  }
  &[data-color='B']{
    background: #e7d16b;
  }
  &[data-color='C']{
    background: #e3a16b;
  }
  &[data-color='G'], &[data-color='X']{
    background: lightgray;
  }
}
