html,
body,
div#root {
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

$header-color: rgb(80, 68, 64);
$ws-label-color: rgb(60, 48, 44);
html,
body,
.main,
#root {
  background-color: #f1ece8 !important;
}
body {
  font-family: 'Open Sans';
  color: #6e625e;

  &.if {
    color: #6e625e;
  }
}

.full-width {
  width: 100%;
}

.if.heading.small {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  font-variation-settings: "wght" 64;
}

.close-icon {
  position: relative;
  top: 0.17em;
  height: 16px !important;
  width: 16px !important;
}
.if.icon {
  filter: grayscale(0) brightness(1);
}
[disabled] .if.icon {
  filter: grayscale(1) brightness(2.4);
  transition: filter 0.2s;
}
.if.button > .if.workshop-icon {
  margin-right: 0.5em;
  position: relative;
  top: 0;
}

.if.form-group.end-aligned {
  align-self: end;
}

.ws-header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}

.if.main {
  background-color: #f1ece8;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  //padding: 0;
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;

  @media screen and (min-width: 1025px) {
    padding: 0 15rem;
  }
}

.if.block.ws-search,
.if.block.ws-results {
  width: calc(100% + 30rem);
  max-width: 1200px;
  align-self: center;
}

.if.expandable {
  padding: 0.675rem 1rem;
  min-height: 4rem;
  align-items: center;
}

.ws-error {
  margin: 2rem;
}
.error-display {
  background-color: #FFFFFF;
  margin: 1.5rem 1.5rem 0;
  .if.alert-banner {
    font-weight: 400;
    letter-spacing: normal;
    font-family: If Sans, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-variation-settings: "wght" 82;
    color: #331e11;
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    background-color: #ffc9a6;
    padding: 1.5rem;
    border-radius: 0.375rem;
    margin-bottom: 64px;
    min-height: 3rem;
    .if.heading.smallest {
      display: block;
      letter-spacing: normal;
      text-rendering: optimizeLegibility;
      font-weight: 700;
      margin-bottom: 8px;
      font-size: 1.125rem;
      line-height: 28px;
      font-variation-settings: "wght" 126;
      font-family: inherit;
    }
  }
}
.if.is-invalid ~ .if.input-error {
  font-size: 1em;
  letter-spacing: normal;
  font-family: "If Sans", Arial,Serif;
  font-weight: 400;
  margin-left: 0;
  display: block;
  position: relative;
  order: 3;
  margin-top: 0.5rem;
  color: #bb320c;
  line-height: 12px;
  font-variation-settings: "wght" 82;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.term-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr minmax(300px, 1fr);
  grid-gap: 2em;
  color: #222;
  border-bottom: 1px solid #ddd;
  padding: 1em 0;
}

.grid-header {
  text-decoration: underline;
  border-bottom: unset;
}

.manufacturer-rating {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr auto 1fr 1fr;
}

.if.panel.is-expandable > .title + .if.content {
  padding: 1rem;
}

.if.button.filled.create-ws-btn {
  max-width: 320px;
  width: 100%;
  margin: 1rem 0.65rem 0;
  display: block;
}

button.delete-ws {
  appearance: none;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-separator {
  border-left: 1px solid #6e625e;
  height: auto;
  display: flex;
  flex: 0 0 auto;
  margin: 0 0.5rem;
}

.disabled {
  cursor: auto;
  opacity: 0.45;
}
