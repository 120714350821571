.deductible.modal {
  width: 100vw;
  height: 100%;
  max-width: unset;
  .if.content {
    padding-left: 40px;
    padding-right: 40px;
  }
  .checkboxes {
    display: flex;
    align-items: center;
    .if.checkbox {
      margin-right: 8px;
      width: 22px;
      height: 22px;
    }
    .if.label {
      margin: unset;
      height: 3rem;
      align-items: center;
      font-variation-settings: "wght" 78;
    }
  }
  .footer>.button {
    margin: unset !important;
  }
}
@media screen and (min-width: 75rem) {
  .deductible.modal{
    width: 600px;
    height: auto;
    .input-field {
      width: 296px;
    }
  }
}
