.collapsible {
  padding: 1rem;
  cursor: pointer;
  //background-color: $color-lightBeige-background;
  overflow: hidden;
  border: 1px solid transparent;

  &:hover {
    //border: 1px solid $color-blue-accent;
  }

  &:nth-child(even) {
    //background-color: $color-lightestBeige-background;
  }

  &:last-of-type {
    margin-bottom: 1.5rem;
  }

  &.closed {
    height: 4rem;
    min-height: 4rem;
    flex-direction: row;
  }

  &.open {
    height: fit-content;
    min-height: 4rem;
  }

  .collapsible-btn {
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  h4 {
    margin: 10px 5rem;
  }

  &:nth-child(odd) {
    //background-color: $color-beige-background;
  }
}
