.if.list.suggestions {
  width: calc(100% - 1.3rem);
  margin: 0 0.65rem;
  padding: 0;
  background-color: #faf9f7;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 76px;
  left: 0;
  z-index: 9;
  max-height: 76px;
  overflow-x: hidden;
  overflow-y: auto;
  color: #6e625e;

  &.no-drop-down {
    background-color: transparent;
    box-shadow: none;
  }

  @media screen and (min-width: 1025px) {
    max-width: 320px;
  }

  .if.list-item {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: pointer;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    &.heading {
      padding: 0.5rem 1.25rem;
      font-size: 0.9rem;
      line-height: 1.5rem;
    }

    button.suggestion {
      width: 100%;
      padding: 0.5rem 1.25rem;
      border: none;
      background: transparent;
      appearance: none;
      color: unset;
      font-size: initial;
      text-align: left;

      &:focus-visible {
        //outline: $color-blue-accent auto 1px;
      }
    }

    &:nth-child(even) {
      //background-color: $color-lighterBeige-background;
    }
  }
}
