
.if.input-error {
  font-size: 12px;
  line-height: 12px;
  margin-left: 0;
  display: block;
}
.service-ratings {
  .if.input-error {
    margin-left: 6.2em;
    max-width: 20rem;
    letter-spacing: 0.5px;
    line-height: 14.5px;
    color: #bb320c;
  }
}

.add-service {
  padding-left: 1em;
  padding-bottom: 1.5em;
  h3 {
    margin-top: 1em !important;
    margin-bottom: 0.6em !important;
  }
  .type-select {
    display: inline-block;
  }

  .button.add-service-button {
    display: inline-flex;
    margin-left: 0.5em;
    padding: 0 1em;
    .if.workshop-icon {
      height: 1em;
      margin-top: -0.3em;
    }
  }
  .type-select {
    width: 12.5em;
  }
}

@keyframes disappear {
  from {opacity: 100%;}
  to {opacity: 0;}
}

.workshop-field > .if.label,
.service-rating {
  .if.workshop-icon.check {
    margin-left: 0.5em;
    color: #0054f0;
    animation: disappear 1s linear 3s 1 forwards;
  }
}
