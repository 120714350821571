
.if.is-open.popover {
  position: fixed;
  transform: translate(-50%, calc(-100% - 1.2em));
  z-index: 9999;
  padding: 0.1em 0.5em;
  min-height: auto;
  width: auto;
  background: white;
  box-shadow: 0 5px 24px -8px #331e11;
  box-sizing: border-box;
  &::before {
    top: calc(100% - 0.3em);
    border-top-color: white;
    left: calc(50% + 0.4rem);
  }
  .popover-text {
    color: #6E625E !important;
    font-size: 0.9em;
  }
}
