

.workshop-history {
  margin: 2em auto;
  max-width: 50em;
  .workshop-history-row {
    .title {
      font-weight: bold;
      display: grid;
      grid-template-columns: 9em auto;
    }
    .description {
      font-style: italic;
    }
    padding: 1em;
    margin-bottom: 1em;
    position: relative;
    background: white;
    z-index: 1;
    border-radius: 0.3em;
    transition: transform 0.3s cubic-bezier(0, 0, 0, 0.99);
    &:hover {
      transform: scale(1.02);
    }
    &:before {
      border-radius: 0.3em;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      display: inline-block;
      box-shadow: -0.5em 1.5em 2em -1em rgba(51, 30, 17, 0.34);
    }
  }
}
