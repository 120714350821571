
.fast-search {
  margin: 1em auto 0 auto;
  max-width: 50em;
  width: 100%;
  .terms {
    margin: 0 0 0.5em 0;
    padding: 0;
    width: 100%;
    input.if.input-field {
      width: 100%;
    }
  }
  .results {
    .workshop {
      border-radius: 0.375rem;
      color: #331e11;
      text-decoration: none;
      background: #e8e0d9;
      border: 2px solid #e8e0d9;
      padding: 0.3em 0.7em;
      margin: 0.5em 0;
      display: flex;
      flex-direction: row;
      //flex-wrap: wrap;
      transition: all 0.1s cubic-bezier(0, 0, 0.04, 0.99);
      position: relative;
      z-index: 1;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
      & > * {
        flex-basis: 50%;
      }
      &:hover {
        box-shadow: -1em 2em 3em rgba(0, 0, 0, 0.2);
        z-index: 2;
        transform: scale(1.05);
        background-color: #f1ece8;
        .expand {
        }
      }
      .code {
        margin-right: 0.3em;
        display: inline-block;
      }
      .service-icons {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        padding-right: 2em;
        align-items: center;
        > div {
          flex-grow: 0;
          flex-shrink: 0;
          .disabled {
            visibility: hidden;
          }
        }
        .service-icon {
          margin-right: 10px;
          margin-left: 10px;
          width: 27px;
          height: 27px;
          > .icon {
            &.blue {
              filter: grayscale(0.65) hue-rotate(162deg) brightness(0.43);
            }

            &.blue:hover {
              filter: none;
            }

            &:hover + .expand {
              box-shadow: -1em 2em 3em rgba(0, 0, 0, 0.4);
              //transform: translateY(0em);
              display: inline-block;
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      .expandInfo {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 3em;
        background-color: hsla(28deg, 25, 50%, 0.15);
        .icon {
          width: 100%;
          height: 100%;
          background-size: 1.2em;
          background-position: 50%;
        }
      }
      .expandInfo:hover + .expand {
        transform: translateY(0em);
        display: inline-block;
        visibility: visible;
        opacity: 1;
        box-shadow: -1em 2em 3em rgba(0, 0, 0, 0.4);
      }
      .expand {
        //transform: translateY(-2em);
        transition: none;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
        position: absolute;
        top: calc(100% + 0.5em);
        left: 20em;
        //width: calc(100% - 25em);
        right: 0;
        height: auto;
        //display: none;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        background-color: #f1ece8;
        border: 2px solid #e8e0d9;
        pointer-events: none;
        padding: 0.6em 0em 1.5em 0.6em;
        padding: 0;
        box-sizing: border-box;
        > .section, .workshop-section {
          padding: 0 !important;
          .heading {
            display: none;
          }
          .service-rating {
            margin: 0;
          }
          .service-rating-header {
            height: 2em;
          }
          .manufacturer-rating {
            height: 1.3em;
          }
          .hidden-in-search
          {
            display: none;
          }
        }
      }
    }
    .explanation > * {
      display: inline-block;
      margin-right: 0.6em;
      background-color: #e8e0d9;
      padding: 0 0.6em;
      font-size: 0.8em;
      border-radius: 0.4em;
    }
    .extra-filters {
      display: flex;
      justify-content: space-between;
      select.service-select {
        padding: 0 1.6em 0 0.5em;
        height: 1.7em;
        line-height: 1em;
        width: auto;
        background-position: right;
        border: 0;
        background-color: transparent;
        font-weight: bold;
        &[data-selected=''] {
          opacity: 0.7;
        }
        &:disabled {
          opacity: 1;
          background-color: transparent;
          background-image: none;
          color: #473428;
        }
      }
      label + label {
        margin-left: 1em;
      }
      margin-bottom: 0.3em;
    }
    .map {
      height: 20em;
      margin-top: 0.5em;
      a,
      .gm-style-iw.gm-style-iw-c,
      .gm-style-iw-d,
      .gm-style .gm-style-iw-t {
        text-decoration: none;
        color: #331e11;
        background: #ebe5e0;
        scrollbar-color: #ebe5e0 #ebe5e0;
        &::-webkit-scrollbar {
          background: #ebe5e0;
          display: none;
        }
      }
      .gm-style-iw-d {
        padding: 0 0.8em 0.8em 0;
      }
      .gm-style-iw-t::after {
        background: linear-gradient(45deg, #ebe5e0 50%, transparent 51%, transparent 100%);
      }
    }
    .show-more {
      margin-top: 2em;
      button {
        margin-left: 1em;
      }
    }
  }
  padding-bottom: 2em;
  .search-help {
    position: relative;
    .help-title {
      display: flex;
      margin: 1em 0 2em 0;
      .if.icon.ui {
        margin-right: 0.5em;
      }
      h1 {
        display: flex;
        margin: unset;
        align-self: center;
      }
    }
    img {
      width: 100%;
      border-radius: 0.6em;
      box-shadow: -0.5em 1.7em 4em rgba(0, 0, 0, 0.6);
      margin-top: 1em;
    }
    .next,
    .prev {
      position: absolute;
      left: 100%;
      height: 100vh;
      top: 0;
      bottom: 0;
      width: 40em;
      padding: calc(50% + 3em) 1em 1em;
      box-sizing: border-box;
      cursor: pointer;
      .if.icon.ui {
        width: 3em;
        height: 3em;
        background-size: cover;
      }
      transition: all 0.2s ease-out;
      &:hover {
        padding-left: 2em;
        padding-right: 2em;
        .if.icon.ui {
          filter: grayscale(1) contrast(2);
        }
      }
    }
    .prev {
      left: unset;
      right: 100%;
      text-align: right;
    }
    .card {
      position: absolute;
      transform: translateX(100vw);
      transition: all 0.4s cubic-bezier(0, 0, 0.15, 0.96);
      &.on {
        transform: translateX(0vw);
      }
      &.past {
        transform: translateX(-100vw);
      }
      .p {
        margin-bottom: 2rem;
        display: inline-block;
        span {
          background-color: #e3dbd3;
          border-radius: 0.2em;
          padding: 0.1em 0.3em;
          font-weight: bold;
        }
      }
    }
  }

  .back-button,
  .help-button {
    display: inline-flex;
    padding: 0 0.5em;
    height: 2.3em;
    margin: -0.6em 0em -0.6em 0.2em !important;
    position: relative;
    top: 0.3em;
    zoom: 1;
  }
}
.fast-search-body {
  overflow-y: scroll;
  background-color: #f1ece8;
  .if.main {
    padding: 0 1em;
    background-color: #f1ece8;
    //overflow: hidden;
  }
  #root {
    height: unset;
  }
}
.fast-search-help-body {
  overflow: hidden;
  #root {
    height: 100%;
  }
  body {
    overflow: hidden;
  }
}
.map-cluster {
  & > img {
    display: none;
  }
  background-color: #e8e0d9c2;
  border: 1px solid #331e11;
  border-radius: 50%;
  width: 0;
  left: 100%;
  position: absolute;

  &-3 {
    border-width: 0.2em;
  }

  &-2 {
    border-width: 0.15em;
  }

  &-1 {
    border-width: 0.1em;
  }
}
