
.summary {
  td.if,
  th.if {
    padding: 0 1em 0 0 !important;
    white-space: nowrap;
    border-bottom: 0.0625rem solid rgba(110,98,94,0.2);
  }
  td.null {
    font-style: italic;
    color: lighten(#6e625e, 20%) !important;
  }
  th {
    text-align: left;
  }
  table.if {
    width: auto;
  }
}
