input[type='date'].if.date::-webkit-calendar-picker-indicator {
    display: inherit;
    background-image: none;

}

@supports (-moz-appearance:none) {
    input[type='date'].if.date {
        background-image: none;
    }
}

.if.workshop-icon.close-icon {
  position: relative;
  filter: hue-rotate(140deg);
  color: #0054f0;
}


