
.no-content-text {
  font-style: italic;
  color: lighten(#6e625e, 10%);
}

.no-rating-text {
  color: lighten(#6e625e, 20%) !important;
}

.no-content-text-lighter {
  color: lighten(#6e625e, 40%);
  user-select: none;
}
.no-content-label {
  user-select: none;
}
