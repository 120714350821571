.if.toggle[type='checkbox'] {
  & + label:after {
    transition: none;
    z-index: 0;
    width: 28px;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0;
    background-color: #faf9f7;
    right: 20px;
    box-shadow: inset 0 0 0 1px #331e11;
    border-radius: 28px;
  }
  & + label:before {
    transition: none;
    z-index: 0;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    width: 48px;
    right: 0;
    padding: 0;
    line-height: 28px;
    border: 1px solid #331e11;
    border-radius: 28px;
    background-color: #e8e0d9;
  }
  &:checked + label:before {
    background-color: #0054f0;
  }
  &:checked + label:after {
    background-color: #faf9f7;
    right: 0;
  }
}
