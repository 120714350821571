
.service-ratings .service-rating .manufacturer-rating {
  padding-right: 1em;
  padding-left: 3.8em;
  &:hover {
    background-color: #f1ece8;
  }
  display: flex;
  grid-gap: unset;
  height: 1.9em;
  h4 {
    margin-left: 0.4em;
    .warning {
      height: 1.3em;
      margin-left: 0.2em;
      position: relative;
      top: 0.2em;
      width: 1.3em;
      display: inline-flex;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 16px 16px;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg class='nc-icon-wrapper' stroke-linecap='square' stroke-width='1.5' fill='%230054f0' stroke='%230054f0'%3E%3Cpath fill='none' stroke-miterlimit='10' d='M2 29L16 3l14 26H2z'/%3E%3Cpath data-color='color-2' fill='none' stroke-miterlimit='10' d='M16 13v7'/%3E%3Ccircle data-color='color-2' data-stroke='none' cx='16' cy='24' r='1' stroke='none'/%3E%3C/g%3E%3C/svg%3E");
    }
  }
  .no-rating {
    color: #6e625e;
    opacity: 0.8;
  }

  .steering-campaign,
  .exclude-customer-reception {
    margin-left: 0.5em;
    input {
      display: none;
    }
    label {
      &.editing {
        cursor: pointer;
      }
      position: relative;
      span {
        & > .if.workshop-icon {
          width: 1.2em;
          height: 1.2em;
          background-size: 100% !important;
          opacity: 0.8;
          transition: all 0.1s ease-out;
          display: flex;
          align-items: center;
          justify-content: center;
          &.close {
            position: absolute;
            left: 0;
            filter: drop-shadow(1px 1px 0px black);
            display: none;
          }
          &.blue,
          &.on {
            opacity: 1;
            filter: contrast(1.2);
            background-position: 60% !important;
            &.close {
              pointer-events: none;
              display: inline-block;
              background-color: transparent;
              top: -5px;
              filter: drop-shadow(0px 1px 0px blue) hue-rotate(136deg);
              width: 1.5em;
              height: 1.5em;
              left: -3px;
            }
          }
          &.blue {
            border-radius: 50%;
            background-size: 70% !important;
            background-color: #b4ceff;
            svg {
              width: 70%;
              height: 70%;
            }
          }
        }
      }
    }
  }
}
