
.customer-services {
  .customer-service {
    background-color: #e8e0d9;
    margin: 0 2.5em;
    padding: 1.1em !important;
    &:nth-child(even) {
      background-color: white;
    }
    .if.delete-button {
      padding: 0;
      min-width: 2em;
      height: 2em;
      margin-left: -0.5em;
      top: -0.2em;
      > .if.workshop-icon {
        height: 1em;
        background-size: contain;
        margin: 0;
        top: 0;
        filter: hue-rotate(140deg);
      }
    }
    h4 {
      white-space: nowrap;
      margin-left: 0.1em !important;
      margin-bottom: 0.3em !important;
      .if.workshop-icon {
        width: 1.3em;
        height: 1em;
        background-size: contain;
        position: relative;
        top: 0.15em;
      }
    }
  }
}
