.if.container {
  width: 100%;
  height: inherit;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}

.screen-width-container {
  width: 100%;
  height: 100vh;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-top: 1px solid #e8e0d9;

  @media screen and (min-width: 1025px) {
    width: calc(100% + 30rem);
    margin-left: -15rem;
  }

  .left-page-container {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }

  .right-page-container {
    width: 15rem;
    padding: 1rem;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
}
.screen-width-container,
.workshop-history {
  .fixed-on-top {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: fixed;
    right: 1rem;
    button {
      width: 13rem;
      margin: 0;
      padding: 0;
      &.filled[disabled] .icon {
        filter: brightness(0.7) sepia(0.3);
      }
    }

    .if.button {
      margin: 0;
      width: 10em;
      margin-top: 1em;
      .check-circle {
        position: relative;
        top: 0;
      }
    }

    .icon {
      background-size: 18px;
    }
  }

  .responsive-menu {
    .if.button {
      width: 48px;
      height: 48px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      @media screen and (min-width: 1366px){
        width: 10em;
        margin: 1em 0 0;
        justify-content: center;
      }
    }
  }
}

.pop-up {
  background-color: #f6f3f0;
  width: calc(min(50em, 100vw));
  margin: 1em auto;
  height: calc(100vh - 2em);
  padding: 0;
  display: block;
  box-shadow: 3px 5px 10px #666666;
  position: relative;
  z-index: 302; /* If Tabs has 301 and overlap */
  overflow-y: auto;

  &-bg {
    width: 100%;
    height: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(95, 95, 95, 0.6);
    display: block;
    z-index: 98;
  }

  .pop-up-content {
    width: calc(100% - 4rem);
    padding: 1rem 2rem;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;

    .bottom-buttons-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .signed-user {
      width: 100%;
      margin: 0;
      margin-left: -2rem;
      margin-bottom: 1rem;
      padding: 1rem 2rem;
      background-color: #e8e0d9;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
    }
    .textarea {
      width: 100%;
      min-width: 100%;
      height: 100%;
      padding-right: 20rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      margin: 0;
    }
  }

  button.if.button {
    margin: 0 0.5rem;
  }
}

.summary {
  width: 100%;
  height: 36.8rem;
  overflow: auto;

  .summary-container {
    width: 100%;
  }

  .summary-wrapper {
    width: 100%;
    height: 168px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .summary-item {
      margin-bottom: 1rem;
    }
  }
}

.if.button.filled.red {
  background-color: #bb320c;
  border-color: #bb320c;
  width: 130px;
  &:hover {
    background-color: #bb320c !important;
  }
}

.flex-column {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.flags {
  text-align: center;

  h1 {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }

  .flag {
    max-width: 15rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.new-ws-btn-wrapper {
  display: flex;
  background-color: #faf9f7;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 1.5rem;

  .new-workshop-link {
    max-width: fit-content;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    color: #6e625e !important;

    &:focus,
    &:active {
      color: #0054f0 !important;

      .if.icon.ui.chevron-right.brown {

      }
    }

    .if.icon.ui.chevron-right.brown:focus,
    .if.icon.ui.chevron-right.brown:active {

    }
  }
}

.ws-result-link {
  text-decoration: inherit;
  color: inherit;
}

.ws-result {
  display: grid;
  padding: 1rem !important;
  grid-template-columns: 40% 1fr auto;
  height: auto;
  align-items: initial;
  background-color: #f1ece8;
  margin-bottom: 1rem;

  .ws-info {
    display: flex;
    flex-direction: column;
  }
}

.ws-name {
  font-variation-settings: 'wght' 126;
}

h3.if {
  margin-top: 1rem;
}

.if.block {
  padding: 0;
  margin-bottom: 1rem;
}

.workshop {
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
    font-variation-settings: 'wght' 126;
    color: #6e625e;
  }

  .if.label {
    color: #6e625e;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
  }

  .section {
    background-color: #f1ece8;
    width: 100%;
    padding: 2em calc(50vw - 23em) 1em calc(50vw - 23em) ;
    margin: 0;

    &.address {
      padding-right: 0;
    }

    &-title {
      align-items: center;
      justify-content: flex-start;
    }

    &-content {
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;

      &.flex-end {
        align-items: flex-end;
      }

      .field {
        min-width: 180px;
        margin-right: 16px;
        margin-left: 16px;
      }
      .if.checkboxes > label {
        margin-right: 0;
      }
    }

    &:nth-child(even) {
      background-color: #f6f3f0;
    }

    .new-services-wrapper {
    }

    ._customer-service,
    .new-customer-service {
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: transparent;
      border: 1px solid transparent;
      padding-top: 1rem;
      padding-left: 0.35rem;
      padding-bottom: 0.35rem;

      &:nth-child(even) {
        background-color: #ede6e1;
      }

      &:hover {
        // color: #60ff00;
        // border: 1px solid #60ff00;
        color: #0054f0;
        border: 1px solid #0054f0;
      }

      .if.form-group {
      }

      .removeButton {
        height: 33px;
        padding-left: 1rem;
        margin-left: 1rem;
        border: 1px solid transparent;
        border-left-color: #6e625e;

        &:hover {
          border: 1px solid #bb320c;
        }
      }
    }

    .new-customer-service {
      width: 100%;
      box-sizing: border-box;
    }

    .customer-service {
      padding: 1rem;

      .value {
        margin-right: 1rem;
      }
    }

    &.service {
      &-section {
        padding: 1rem;

        .services-title {
          margin-left: 4rem;
        }
      }
    }

    &.name-section {
      background-color: #e8e0d9;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
    }

    .diamonds-wrapper {
      position: relative;
      z-index: 3;
      cursor: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .diamond {
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom: 7px solid #dedede;
      position: relative;
      top: -6px;
      margin-left: 0.5rem;

      &:after {
        content: '';
        position: absolute;
        left: -6px;
        top: 7px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top: 7px solid #dedede;
      }

      &.contractA,
      &.prio1 {
        border-bottom: 7px solid rgb(54, 211, 54);
        &:after {
          border-top: 7px solid rgb(54, 211, 54);
        }
      }

      &.contractB,
      &.prio2 {
        border-bottom: 7px solid rgb(255, 251, 0);
        &:after {
          border-top: 7px solid rgb(255, 251, 0);
        }
      }

      &.contractC,
      &.prio3 {
        border-bottom: 7px solid rgb(255, 196, 0);
        &:after {
          border-top: 7px solid rgb(255, 196, 0);
        }
      }

      &.contractG,
      &.prio4 {
        border-bottom: 7px solid rgb(135, 223, 250);
        &:after {
          border-top: 7px solid rgb(135, 223, 250);
        }
      }

      &.contractX,
      &.prio5 {
        border-bottom: 7px solid rgb(230, 8, 8);
        &:after {
          border-top: 7px solid rgb(230, 8, 8);
        }
      }
    }

    .heads {
      font-weight: 100;
      font-variation-settings: 'wght' 100;
      background-color: #f6f3f0;
      padding: 0.5rem 0;
      margin-bottom: 0;
      position: relative;
      z-index: 3;
      cursor: auto;

      &.manufacturers-heads {
        margin-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .select-services-arrows {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    &.add-new-services,
    .add-new-manufacturers {
      width: 100%;
      background-color: #faf9f7;
      .heads {
        background-color: transparent;
      }
    }
    &.add-new-manufacturers {
      width: 100%;
      padding: 1rem;
      margin: 0;

      .grid-4-col-row {
        margin: 0;
      }
    }

    .info {
      width: -webkit-fill-available;

      &.manufacturer {
        min-width: 194px;
      }
    }

    .all-manufacturer-wrapper {
      width: inherit;
      padding: 0.5rem 1rem;
      margin: 0;
      box-sizing: border-box;
      background-color: transparent;

      &:nth-child(even) {
        background-color: #f6f3f0;
      }

      h3 {
        font-size: 1.125rem;
        font-weight: normal;
        font-variation-settings: 'wght' 80;
        margin: 0;
        padding: 0;
      }

      .diamond {
        margin-left: 0;
        margin-right: 0.25rem;
      }
    }

    .manufacturers-wrapper {
      .if.form-group.no-left-padding {
        padding-left: 0;
      }
    }

    .manufacturer-rating-wrapper {
      color: #6e625e;
      padding: 0;
      border-bottom: 1px solid rgba(110, 98, 94, 0.5);
      margin-top: 1rem;

      &:last-of-type {
        border: 1px solid transparent;
      }

      &.editing {
        //border: none;
        position: relative;
        z-index: 3;
      }

      &:hover {
        .manufacturer-title-wrapper {
          color: #FF8569;
          border: 1px solid #FF8569;
        }
      }
    }

    .manufacturer-title-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.65rem 1rem;
      border: 1px solid transparent;

      &:hover {
        color: #FF8569;
        border: 1px solid #FF8569;
      }
    }

    .manufacturer-content {
      background-color: transparent;

      &.editing {
        padding: 1rem;
        margin-bottom: -1px;
      }
    }

    .all-manufacturer-wrapper {
      background-color: transparent;
      position: relative;
      z-index: 3;
      cursor: auto;
    }

    select#manufacturer {
      max-width: 11.5rem;

      &.if.single-select-no-arrow {
        height: inherit;
        padding: 0;
        background-image: none;
        background-color: #faf9f7;
      }

      option.option {
        width: calc(100% - 3rem);
        max-width: 11.5rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0.5rem 1.5rem;
      }
    }
  }

  .map-container {
    width: 100%;
    max-width: 40em;
    height: 30em;
    &.no-position {
      color: #958983;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #c4bdba;
      font-style: italic;
      font-weight: 100;
      height: 4em;
    }
  }

  .ifContacts-input-wrapper {
    .if.list.suggestions {
      max-height: 129px;
    }
  }

  .grid-3-col-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 1rem 1rem 1rem;
  }

  .grid-2-col-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1rem 1rem 1rem;
  }

  .grid-4-col-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 1rem 1rem 1rem;
  }

  .buttonBase {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;
  }

  .editButton {
    @extend .buttonBase;
    //@include if-icon-ui-direct-edit;
    position: relative;
    z-index: 2;
  }

  .applyButton {
    @extend .buttonBase;
    //@include if-icon-ui-check;
    position: relative;
    z-index: 2;
  }

  .discardButton {
    @extend .buttonBase;
    //@include if-icon-ui-close;
    position: relative;
    z-index: 2;
  }

  .removeButton {
    @extend .buttonBase;
    //@include if-icon-ui-trashcan;
    position: relative;
    z-index: 2;
  }

  .leftArrowButton {
    @extend .buttonBase;
    //@include if-icon-ui-arrow-left;
  }

  .rightArrowButton {
    @extend .buttonBase;
    //@include if-icon-ui-arrow-right;
  }

  .center {
    text-align: center;
  }

  .field {
    margin-bottom: 1rem;

    &.title {
      margin-bottom: 0;
    }

    .label {
      font-variation-settings: 'wght' 400;
      margin-right: 4px;
    }
  }

  .serviceRatings {
    .serviceRating:nth-child(even) {
      background-color: #ede6e1;
    }

    .serviceRating {
      background-color: #e8e0d9;

      h4 {
        margin-block-start: 0;
        margin-block-end: 0.2rem;
      }
    }
  }

  .serviceRating {
    .editButton,
    .applyButton,
    .removeButton,
    .discardButton,
    button,
    select,
    input {
      position: relative;
      z-index: 3;
    }

    .title {
      width: 100%;
      height: 35px;
      align-items: center;
    }

    .removeButton {
      height: 33px;
      padding-left: 1rem;
      margin-left: 1rem;
      border: 1px solid transparent;
      border-left-color: #6e625e;

      &:hover {
        border: 1px solid #bb320c;
      }
    }

    button,
    select,
    input {
      cursor: pointer;
    }

    h4 {
      margin: 0 0.25rem;
    }

    .service-rating {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        width: fit-content;
        align-items: center;
        position: relative;
        z-index: 3;
        cursor: auto;
      }
    }

    .service-wrapper {
      margin-top: 1rem;

      .flex-row {
        flex-wrap: wrap;
      }

      .if.form-group.no-left-padding {
        padding-left: 0;
      }
    }
  }

  .address-grid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }
  .ws-map {
    min-height: 20rem;
  }

  .manual-geocoding {
    #manualEditLabel {
      padding-left: 0.5rem;
    }
  }
  .geocoding-container {
    display: flex;
    flex-direction: row;

    .small-button {
      height: min-content;
      width: min-content;
      padding: 0;
      margin: 0;
      color: black !important;
    }

    .small-button:focus {
      outline: none;
    }
  }

  &.delete-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
  }
}
.fixed-on-top-wrapper {
  height: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .button.outlined {
    background: white !important;
  }
}
.fixed-on-bottom {
  position: fixed;
  bottom: 1em;
  right: 1em;
}
