form {
  max-width: 964px;
}

input[type='number'].if {
  text-align: left;
}

.if.container.ws-form,
.ws-form {
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2.75rem;
  background-color: #f1ece8;
  color: #6e625e;

  @media screen and (min-width: 1025px) {
    width: fit-content;
  }

  .if.dropdown,
  .if.input-field,
  .if.dropdown > select {
    max-width: initial;
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  label.if.label {
    color: #6e625e;
  }
  .if.input-field {
    width: 100%;
    min-width: 320px;
    color: #6e625e;
  }

  &.create-container {
    width: 100%;

    @media screen and (min-width: 765px) {
      width: fit-content;
    }
  }

  h2.if.heading.create-heading {
    margin-top: 2.75rem;
  }

  .loader-wrapper {
    margin: 0 0.65rem;
    align-items: center;
  }

  .code-wrapper {
    position: relative;
    margin-bottom: 3.25rem;

    .if.form-group {
      width: 100%;
    }

    .if.list.suggestions {
      top: 76px;
    }
  }
}

.ws-form-wrapper {
  width: 100%;
  background-color: #f1ece8;
  display: flex;
  justify-content: center;

  &.search {
    padding: 2.75rem 0 0 0;
    flex-direction: column;
    align-items: flex-start;

    .if.container.ws-form {
      padding-left: 0;
      padding-right: 0;
      align-items: center;
    }

    h2.if.heading.small {
      padding: 0 0.65rem;
    }

    h3.filters-title {
      margin-bottom: 6.625rem;
    }

    .searchbox-wrapper {
      width: initial;
      max-width: 100%;
      position: relative;
      margin-left: 1rem;
      margin-right: 1rem;

      @media screen and (min-width: 1025px) {
        width: fit-content;
      }

      .if.form-group {
        padding: 0;
        width: 100%;

        @media screen and (min-width: 1025px) {
          width: fit-content;
        }

        input[type='text'] {
          padding-left: 2.75rem;
          min-width: 265px;

          @media screen and (min-width: 1025px) {
            min-width: 367px;
          }
        }
      }

      .if.list.suggestions {
        padding: 0;
        width: 100%;
        max-height: 137px;
        margin: 0;
        top: 3rem;

        @media screen and (min-width: 1025px) {
          max-width: 367px;
        }
      }
    }

    .if.input-field {
      padding-left: 46px;
      padding-right: 46px;
      z-index: 8;
    }

    .search-settings-btn {
      height: 46px;
      width: 46px;
      margin: 0;
      padding: 0;
      background: transparent;
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      z-index: 10;
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
    }

    .icon-search {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 9;
      cursor: text;
    }

    .icon-settings {
      position: relative;
      top: 0;
      left: auto;
      right: 0;
      z-index: 10;
    }

    .search-buttons-wrapper {
      align-items: center;
      justify-content: center;
      width: fit-content;
      align-self: center;
    }

    .search-button {
      max-width: fit-content;
      align-self: center;
    }

    .reset-filters-button {
      max-width: fit-content;
      align-self: flex-start;
      align-items: center;
      .icon-clean {
        margin-right: 0.25rem;
      }
    }

    .filters-container {
      padding: 0;
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
    }

    .manufacturer-filters-container {
      width: 100%;
      min-width: $slider-min-width;
      align-items: center;

      h3.if {
        align-self: flex-start;
      }
    }

    .slider > .slider-wrapper {
      .flex-column.loader-wrapper.hidde {
        width: 100%;
        display: flex;
      }
    }

    .filter-column {
      width: 9.5rem;
      padding: 0;

      label {
        hyphens: auto;
      }
    }

    .flex-column {
      margin-bottom: 1.5rem;
    }

    .search-filter {
      align-items: baseline;
      justify-content: flex-start;
      line-height: 2rem;

      label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .not-clickable {
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }
  }
}

.ws-modal-form {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  overflow-y: auto;
  max-height: 100%;
  background-color: #f6f3f0;

  .title {
    font-variation-settings: 'wght' 64;
    position: fixed;
    width: 100%;
    z-index: 200;
    background-color: #e8e0d9;

    .if.container {
      display: flex;
      align-items: center;
      line-height: 2rem;
      justify-content: space-between;
      font-size: 1.5rem;
      padding: 0 1.5rem;
      height: 4.5rem;
    }

    .close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      margin: 0.5rem;
      padding: 0.5rem;
    }
  }
  .if.icon.close {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    flex: 0 0 auto;
    background-size: 1.5rem 1.5rem;
  }

  .ws-form {
    padding: 4.5rem 2rem 2rem;
    margin: 0;
  }
}

.if.form-row {
  display: flex;
  flex-wrap: wrap;
}

.ws-subform,
.ws-sub-expandable {
  display: flex;
  flex-wrap: wrap;
  background-color: #f6f3f0;
  border: 1pt solid #6e625e;
}

.ws-subform {
  padding: 1rem;
}

.if.form-group,
.if.selection-controls {
  padding: 0 0.65rem;

  &.stretch {
    flex: 1 1 auto;
  }
}

.if.form-group {
  //Arreglar estos estilos
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  @media screen and (min-width: 765px) {
    width: initial;
  }

  &.input-error {
    font-size: 1rem;
    margin-top: 0;
    padding: 0 1rem;

    @media screen and (min-width: 765px) {
      width: 320px;
    }
  }

  &.flex-row {
    flex-direction: row;
  }

  &.full-width {
    width: 100%;
    .if.input-field {
      width: 100%;
    }
  }

  label.bold {
    font-weight: 400;
    font-variation-settings: 'wght' 400;
  }
}

.if.form-actions {
  display: flex;
  justify-content: flex-end;
}

.ws-form-section {
  margin-top: 1.5rem;
}
.field.component > div:not(.label) {
  white-space: pre-wrap;
}
.if.select {
  padding: 0;
  width: 100%;

  @media screen and (min-width: 1025px) {
    width: 296px;
  }

  option {
    padding: 0.5rem 1.5rem;
  }

  &.multiple-select {
    height: 12rem;
    background-image: none;
  }

  &.single-select {
    height: 3rem;
    padding: 0.5rem 3rem 0.5rem 1.5rem;
  }
}

.form-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 765px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-group {
    color: #6e625e;

    .label {
      margin-top: 0.65rem;
    }

    input {
      min-width: 250px;
      min-height: 24px;
    }
  }
}
.if.form-group.workshop-field {
  height: 96px;
}
