
.service-ratings {
  .service-rating {
    margin: 0 2.5em;

    background-color: #e8e0d9;
    &:nth-child(even) {
      background-color: white;
    }

    h4 {
      margin-block-start: 0;
      margin-block-end: 0.2rem;
    }

    & + & {
      border-top: 1px solid red;
    }

    border: 2px solid transparent;

    &.highlighted {
      border: 2px solid rgba(black, 0.2);
    }
    &.can-open:hover {
      border: 2px solid rgba(#331e11, 0.3);
    }

    .if.delete-button {
      padding: 0;
      min-width: 2em;
      height: 2em;
      > .if.workshop-icon {
        height: 1em;
        background-size: contain;
        margin: 0;
        top: 0;
        filter: hue-rotate(140deg);
      }
    }
    .service-rating-header,
    .manufacturer-rating {
      .all-toggle {
        margin-left: 0.4em;
      }
      .if.toggle[type='checkbox'] + label {
        zoom: 0.7;
        font-size: 1.2em;
        padding-right: 3.5rem;
        margin-right: 0;
        width: auto !important;
      }
    }

    .manufacturers-list {
      color: #6e625e;
      font-size: 0.7em;
      margin-left: 0.7em;
      flex-grow: 1;
      display: flex;
      overflow: hidden;
      & > div {
        //max-width: 30em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }

    &.can-open .service-rating-header {
      cursor: pointer;

      & > .icon {
        margin: 0 0.3em 0 0.7em;
        transition: all 0.2s;
      }
      &.is-open > .icon {
        transform: rotate(90deg) translate(1px, -1px);
      }
    }
    &:not(.can-open) .service-rating-header {
      & > .icon {
        display: none;
      }
      & > h4 {
        margin-left: 1.1em;
      }
    }
    .service-rating-header,
    .manufacturer-rating {
      display: flex;
      height: 3em;
      align-items: center;
      .contract-type {
        margin-left: 0.4em;
      }
      .contract-type,
      .priority-type {
        color: #6e625e;
      }
      h4 {
        white-space: nowrap;
        margin-left: 0.1em;
        //text-transform: uppercase;
        margin-bottom: 0;
        .icon {
          width: 1.3em;
          height: 1.3em;
          background-size: contain;
          position: relative;
          top: 0.2em;
          margin-left: 0.2em;
        }
        &.strike {
          text-decoration: line-through;
        }
      }
      .header-spacer {
        flex-grow: 1;
      }
      .service-rating-header-diamonds {
        display: flex;
        margin: 0 0.2em 0 0;
        .diamond {
          margin: 0 0.5em 0 0;
        }
      }
    }

    .service-rating-container {
      //max-width: 30em;
      //can't use because of collapse
      //padding: 1em 0 1em 0;
      height: 0;
      transition: height 0.4s cubic-bezier(0, 0, 0, 1);
      overflow: hidden;
      //:first-child
      //{
      //  margin-top: 1em;
      //}
      //:last-child {
      //  margin-bottom: 0.2em;
      //}
      &.is-open {
        height: auto;
      }
    }
    .manufacturer-rating-spacer {
      height: 0.8em;
    }

    &:nth-child(even) .manufacturer-rating:hover {
      background-color: #f6f3f0;
    }

    .deductible-search {
      padding-bottom: 1em;
      padding-left: 3.8em;
      .if.content {
        padding-bottom: 0;
        min-height: 14em;
      }
      .loader-wrapper {
        margin-bottom: 0;
        margin-top: 1em;
      }
      .form-group {
        margin-bottom: 0 !important;
      }
      .search-button {
        width: min-content;
      }
      .result {
      }
      .input-error {
        margin-left: 0;
      }
    }
  }

  .add-manufacturer-rating {
    display: flex;
    margin-bottom: 1em;
    margin-top: 0.6em;
    padding-left: 4.2em;
    .select.manufacturer-select {
      display: inline-block;
      margin-left: 0em;
      //.icon {
      //  height: 1em;
      //  margin-top: -0.3em;
      //}
      padding: 0 0.6em;
      height: 1.6em;
      //width: 17em;
      //background-position: 15em;
    }
    .if.button {
      display: inline-block;
      margin-left: 0.5em;
      .if.workshop-icon {
        height: 0.8em;
        width: 0.8em;
        top: 0.05em;
      }
      padding: 0 0.6em;
      height: 1.6em;
    }
  }
}
