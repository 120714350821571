[data-cy-only-label] {
  height: 0 !important;
  width: 0 !important;
  float: left;
}
.show-cy {
  [data-cy]:before,
  [data-cy]:after,
  label[data-cy-label]:after,
  [data-cy-only-label]:after {
    content: attr(data-cy);
    float: left;
    position: relative;
    top: -1.5em;
    width: 0;
    text-shadow: -1px -1px 0px white, 1px 1px 0px white;
    color: #aa0000;
    z-index: 4;
    font-family: Tahoma, sans-serif;
    font-size: 10px;
    font-smoothing: none;
    -webkit-font-smoothing: none;
    font-weight: normal;
  }
  label[data-cy-label]:after {
    content: attr(data-cy-label);
  }
  [data-cy-only-label]:after {
    content: attr(data-cy-only-label);
  }
  [data-cy]:after {
    content: attr(data-cy-value);
    top: -0.9em;
  }
  [data-cy].button:before {
    left: -2em;
    top: -2.4em;
  }
}
