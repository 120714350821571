.icon {
  width: 24px !important;
  height: 24px !important;
  background-size: contain;

  &-search {
    background: url(../svg/search.svg) no-repeat center;
  }

  &-settings {
    background: url(../svg/settings.svg) no-repeat center;
  }

  &-chevron-right {
    background: url(../svg/chevron-right.svg) no-repeat center;
  }

  &-chevron-left {
    background: url(../svg/chevron-left.svg) no-repeat center;
  }

  &-chevrons-right {
    background: url(../svg/chevrons-right.svg) no-repeat center;
  }

  &-chevrons-left {
    background: url(../svg/chevrons-left.svg) no-repeat center;
  }

  &-clean {
    background: url(../svg/clean-icon.svg) no-repeat center;
  }

  &-undo {
    background: url(../svg/undo.svg) no-repeat center;
  }

  &-save {
    background: url(../svg/save.svg) no-repeat center;
  }

  &-preview-blue {
    background: url(../svg/preview-blue.svg) no-repeat center;
  }

  &-preview-brown {
    background: url(../svg/preview-brown.svg) no-repeat center;
  }

  &-edit {
    background: url(../svg/edit.svg) no-repeat center;
  }
}
