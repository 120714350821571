.fixed-on-top.flex-column {
  .if.is-open.popover {
    max-width: 255px;
    display: block;
    z-index: 99;
    padding: 0.5rem 1.25rem;
    box-sizing: border-box;
  }
}

/* It belongs here, but _views overwrites them -.-
*.responsive-menu {
  .if.button {
    width: 48px;
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    @media screen and (min-width: 1366px){
      width: 10em;
      margin: 1em 0 0;
      justify-content: center;
    }
  }
}*/
