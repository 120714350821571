.infoBox {
  transform: translateX(-50%);
  .workshop-result-info {
    background-color: #f1ece8;
    padding: 0.4em 0.8em;
    border: 0.2em solid #331e11;
    .code {
      display: block;
    }
  }
}
