@import 'sliderVariables';

.slider {
  &-wrapper {
    width: 100%;
    min-width: $slider-min-width;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    box-sizing: border-box;
  }

  .show {
    display: flex;
  }

  .hidde {
    display: none;
  }

  .paging-container {
    width: 256px;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.625rem;

    .paging {
      width: 32px;
      height: 30px;
      text-align: center;
      display: inline-block;
      margin: 0;
      padding: 0;
      background: transparent;
      background-color: transparent;
      border: none;
      color: #331e11;
      text-decoration: underline;
      cursor: pointer;

      &.current {
        color: #0054f0;
        text-decoration: none;
        cursor: auto;
      }

      .icon.icon-chevrons-left,
      .icon.icon-chevrons-right {
        width: 18px;
        height: 18px;
      }

      .icon.icon-chevrons-right {
        margin-left: 12px;
      }

      &.disabled {
        cursor: default;

        .icon.icon-chevrons-left,
        .icon.icon-chevrons-right {
          opacity: 0.45;
        }
      }
    }
  }
}
