.if.backdrop {
  transition: all .3s ease-in;
  display: block;
  position: fixed;
  z-index: 700;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(110,98,94,.4);
}
.if.backdrop.is-open {
  pointer-events: auto;
  opacity: 1;
  animation: ifKeyframesShowBackdrop .2s ease-in forwards;
}
.if.backdrop.is-open+.if.modal {
  display: flex;
  animation: ifKeyframesShowModal .3s ease-in-out forwards;
}
.if.modal {
  position: fixed;
  z-index: 900;
  top: 50%;
  left: 50%;
  flex-direction: column;
  padding: 0;
  border-radius: 0.75rem;
  margin: 0;
  background-color: #faf9f7;
  box-shadow: 0 8px 4px -2px rgba(110,98,94,0.08),0 20px 32px rgba(110,98,94,0.24);
  color: #331e11;
  transform: translate(-50%,-50%);
  transition: all .2s ease-in;
  max-width: 600px;
}
.if.modal .if.title {
  position: relative;
  display: flex;
  height: 4rem;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0 4rem 0 1.5rem;
  background-color: #f1ece8;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  color: #331e11;
  font-weight: 400;
  letter-spacing: normal;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-variation-settings: "wght" 126;
  font-family: "If Sans", Arial,Serif;
}
.if.modal .if.content {
  position: relative;
  display: block;
  flex-grow: 1;
  font-size: 1.125rem;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "If Sans", Arial,Serif;
  font-weight: 400;
  font-variation-settings: "wght" 78;
  padding: 1.5rem 1.5rem;
}
.if.modal .if.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  letter-spacing: normal;
  font-family: "If Sans", Arial,Serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-variation-settings: "wght" 85;
  padding: 1.5rem 1.5rem;
  button {
    min-width: 100px;
  }
}
@media screen and (min-width: 60rem) {
  width: 600px;
  height: auto;
  max-height: 100%;
}
@media screen and (min-width: 45rem) {
  .if.modal .if.footer {
    height: 7rem;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 2.5rem;
  }
}
@media screen and (min-width: 75rem) {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  font-variation-settings: "wght" 64;
}
@media screen and (min-width: 45rem) {
  .if.modal .if.title {
    padding-right: 4rem;
    padding-left: 2.5rem;
  }
}
@media screen and (min-width: 45rem) {
  .if.modal .if.footer .if.button {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
}
@keyframes ifKeyframesShowBackdrop {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes ifKeyframesShowModal {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}
