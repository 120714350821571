
nav {
  display: flex;
  padding: 0.5em 1em;
  margin-bottom: 0 !important;
  align-items: center;
  font-size: 1.02rem;
  .if.tabs {
    height: 2.4em;
    .if.tab-list {
      height: inherit;
      display: flex;
      list-style: none;
      & > li.if {
        margin: 0 !important;
        display: flex;
        align-items: center;
        padding: 0.75rem 0;
        a.if {
          position: relative;
          margin-right: 0;
          padding: 0 1em;
          cursor: pointer;
          color: #6e625e;
          &::after {
            background-color: #d7d1cd;
            border-bottom: none;
            content: "";
            height: 2pt;
            width: 100%;
            z-index: 2;
            position: absolute;
            top: calc(100% + 3pt);
            left: 0;
          }
          &[aria-selected] {
            color: #331e11;
            font-family: If Sans Medium,Arial,sans-serif;
            font-weight: 400;
            font-variation-settings: "wght" 104;
            width: 100%;
            z-index: 4;
            &::after {
              background-color: #0054f0;
            }
          }
          &:hover {
            color: #331e11;
            font-variation-settings: "wght" 104;
          }
        }
      }
    }
    &:after {
      top: 2.2em;
      width: 100%;
    }
  }
  .if.dropdown-select {
    padding: 0 1.6em 0 0.5em;
    height: 1.7em;
    line-height: 1em;
    width: auto;
    background-position: right;
    margin-left: 0.3em;
    border: 0;
    background-color: transparent;
  }
  .expand {
    flex-grow: 1;
  }
  .if.logo {
    display: block;
    margin: 0 0.5em 0 0.5em;
    width: auto;
    background-position: left;
    border-radius: unset;
    background-size: contain;
    height: 1.6em;
    min-height: unset;
    text-decoration: none;
    color: #331e11;
  }
  label {
    margin-left: 1em;
  }
  & > a {
    margin-right: 1em;
  }
}
nav.if.block.dark {
  padding: 0;
}
