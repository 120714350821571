.tab-container {
  margin-top: 1rem;
  color: rgb(20, 20, 20);

  .tablist {
    padding: 0;
    margin: 0;
    height: 3.2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: center;
    position: relative;
    list-style: none;
    border-top: 1px solid #e8e0d9;

    li {
      padding: 0.75rem 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      margin: 0;
      cursor: pointer;
      height: 100%;
      border: 1px solid #e8e0d9;
      font-size: 1.3rem;
      color: rgb(50, 50, 50);

      &:first-child {
        border-left: 1px solid #e8e0d9;
      }
    }

    li[aria-selected] {
      background-color: rgb(251, 251, 252);
    }

    a[aria-selected] {
      font-family: If Sans Bold, Arial, sans-serif;
      font-weight: 400;
      font-variation-settings: 'wght' 126;
      border-bottom: none;

      &::after {
        border-bottom: none;
        content: '';
        height: 2pt;
        width: 100%;
        z-index: 2;
        background-color: #0054f0;
        position: absolute;
        top: calc(100% + 1pt);
        left: 0;
      }
    }

    .tablist > li:first-child {
      border-left: 1px solid #e8e0d9;
    }
  }

  .tab-content {
    // padding-top: 1rem;
    // margin-top: 0.5rem;
    padding: 0;
    margin: 0;
  }
}
