
.workshop {
  .if.section.workshop-section {
    gap: 0;
    padding-top: 1em !important;
    h1 {
      color: #331E11;
      margin-top: 0;
    }
    h2 {
      .workshop-icon {
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 0.4em;
        background-size: contain;
        position: relative;
        top: 0.17em;
      }
    }
    input.if:not([type='radio']):not([type='checkbox']):not([type='range'])[readonly] {
      background-color: transparent;
      border-color: transparent;
      outline-color: transparent;
      padding: 0;
      margin-right: 2rem;
      @media screen and (min-width: 75rem) {
        margin-right: 0;
      }
    }
    .flex-break {
      flex-basis: 100%;
      height: 0;
    }
  }
}
.fixed-on-top-wrapper {
  button {
    .workshop-icon {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

