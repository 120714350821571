.tooltip {
  display: flex;

  .tooltip-icon {
    width: 14px;
    height: 14px;
    margin: 0 0.5rem;
    padding: 0;
    margin: 0 0.5rem;
    background-color: transparent;
    border-radius: 100%;
    //border: 2px solid $color-lightBrown-text;
    //color: $color-lightBrown-text;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    position: relative;
    z-index: 8;
    cursor: pointer;
  }

  .tooltip-content {
    display: none;
    cursor: pointer;
    z-index: 9;
  }

  .tooltip-icon:hover + .tooltip-content,
  .tooltip-content:hover + .tooltip-content {
    display: flex;
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: auto;
    //background-color: $color-lightestBeige-background;
    //border: 1px solid $color-lighterBeige-background;
    box-shadow: 3px 5px 10px #666666;
  }

  .tooltip-content:hover + .tooltip-content {
    z-index: 10;
  }
}
